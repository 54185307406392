import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../app/app-components/layout"
import _ from "lodash"
import Markdown from "react-markdown"
import { JSONPath } from "jsonpath-plus"
import {
  ClockIcon,
  ShoppingCartIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/solid"
import { motion } from "framer-motion"
import { myAnimations } from "../context/framer-animations"
import BlankCard from "../app/app-components/blankCard"
// import remarkGfm from "remark-gfm"

const PaperTemplate = props => {
  // console.log(props)
  const exam = props.pageContext.exam[0]
  const paper = props.pageContext.paper[0] || {}
  const template = paper?.template || []
  const quizIdentifier = JSONPath("$..sectionId", template) || []
  const paperId = { id: paper?.paperId, name: paper?.paperName }
  console.log(paper)

  return (
    <Layout isBackButton={true} title="Enrollments">
      <motion.div {...myAnimations.fadeIn} className="p-4 pb-24 ">
        <ExamIconComponent
          title={paper?.paperName}
          image={exam.icon.localFile.childImageSharp.gatsbyImageData}
        />
        <div className="pt-8 pb-4 font-base">Full Test</div>
        <PaperFullQuizCard
          paper={paper}
          quizIdentifier={quizIdentifier}
          template={template}
        />

        <div className="pt-8 pb-4 font-base">Sectional Tests</div>
        {template?.sections.map((section, idx) => (
          <PaperSectionsCard
            key={section?.sectionId}
            section={section}
            quizIdentifier={quizIdentifier}
            paperId={paperId}
            isSubjectWise={props.isSubjectWise}
          />
        ))}
        <div className="pt-8 pb-4 font-base">Short Quiz</div>
        <BlankCard text="Coming Soon" />
        <div className="pt-8 pb-4 font-base">Previous Papers</div>
        <BlankCard text="Coming Soon" />

        <div className="hidden pt-8 pb-4 font-base">Syllabus</div>
        <div className="hidden p-4 overflow-hidden bg-white shadow rounded-xl">
          <Markdown children={paper?.syllabus} />
        </div>
        <div className="hidden pt-8 pb-4 font-base">Instructions</div>
        <div className="hidden p-4 bg-white shadow rounded-xl">
          <Markdown children={template?.instructions} />
        </div>
      </motion.div>
    </Layout>
  )
}

export function ExamIconComponent({ title, image }) {
  return (
    <div className="flex flex-col items-center justify-center p-4 bg-white shadow rounded-xl ">
      {image && (
        <div className="w-20 h-20 overflow-hidden">
          <GatsbyImage image={image} alt={title} />
        </div>
      )}

      <div className="flex items-center justify-center mt-2">
        <p className="text-xl font-base ">{title}</p>
      </div>
    </div>
  )
}

function PaperFullQuizCard({ paper, quizIdentifier, template }) {
  let totalQuestions = JSONPath("$...sectionTotalQuestions", template)
  totalQuestions = _.sum(totalQuestions)
  let totalMarks = JSONPath("$...sectionTotalMarks", template)
  totalMarks = _.sum(totalMarks)

  return (
    <div className="flex flex-col p-4 mb-2 bg-white border-b shadow rounded-xl">
      <div className="flex justify-start mb-2">
        <p className="font-base ">{paper?.paperName} - Full Test</p>
      </div>
      <div className="flex justify-between ">
        <div className="flex flex-col space-y-1 text-xs">
          <div className="flex">
            <QuestionMarkCircleIcon className="w-4 h-4 mr-2" />
            <p>{totalQuestions} Q</p>
          </div>
          <div className="flex">
            <ShoppingCartIcon className="w-4 h-4 mr-2" />
            <p>{totalMarks} Marks</p>
          </div>
          <div className="flex">
            <ClockIcon className="w-4 h-4 mr-2" />
            <p>{template?.quizTotalTime} min</p>
          </div>
        </div>

        <Link
          to={`/paperquiz`}
          state={{
            identifier: quizIdentifier,
            quizTime: template?.quizTotalTime,
            paperId: paper.paperId,
          }}
          className="px-8 btn"
        >
          Start
        </Link>
      </div>
    </div>
  )
}

function PaperSectionsCard({ section, quizIdentifier, paperId }) {
  const image = section?.icon?.localFile.childImageSharp.gatsbyImageData
  return (
    <div className="flex justify-start p-4 mb-2 bg-white border-b shadow rounded-xl">
      <div className="flex items-center">
        {image && (
          <div className="w-10 h-10 mr-4 overflow-hidden">
            <GatsbyImage image={image} alt={section.sectionName} />
          </div>
        )}
      </div>
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-col space-y-1 ">
          <p className="font-base text-md ">{section?.sectionName}</p>
          <div className="flex">
            <QuestionMarkCircleIcon className="w-4 h-4 mr-1" />
            <p className="text-xs">{section?.sectionTotalQuestions} Q</p>
          </div>
          <div className="flex">
            <ShoppingCartIcon className="w-4 h-4 mr-1" />
            <p className="text-xs">{section?.sectionTotalMarks} Marks</p>
          </div>
          <div className="flex">
            <ClockIcon className="w-4 h-4 mr-1" />
            <p className="text-xs">{section?.sectionTime} min</p>
          </div>
        </div>

        <Link
          to={`/paperquiz`}
          state={{
            identifier: quizIdentifier.filter(id => id === section?.sectionId),
            quizTime: section?.sectionTime,
            paperId: paperId,
          }}
          className="px-8 btn"
        >
          Start
        </Link>
      </div>
    </div>
  )
}

export default PaperTemplate
